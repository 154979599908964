import jQuery from 'jquery';
import { isDev } from './utils/devUtils';
import { logError } from './utils/sentry';

window.$ = jQuery;
window.jQuery = jQuery;

// check if global libraries are being loaded multiple times.
// Loading the libs might cause issues like https://reactjs.org/docs/error-decoder.html/?invariant=321
// since react is loaded twice etc
if (window.globalLibsLoaded === undefined) {
  window.globalLibsLoaded = true;
} else if (isDev()) {
  // eslint-disable-next-line no-alert
  alert(
    'WARNING: global libraries are loaded twice on this page. This could cause react errors.'
  );
} else {
  logError(
    new Error(`global libraries are loaded twice on - ${window.location.href}`)
  );
}
